import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { reduce, includes, debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { useLocation } from "@reach/router";

import {
  Center,
  Container,
  StyledButton,
  Left,
  Right,
  Search,
  SearchInput,
} from './journal-filter.css';

const JournalFilter = ({
  items,
  filterJournal,
  changeFilterJournal,
  searchJournal,
  changeSearchJournal,
}) => {
  const [inputSearch, setInputSearch] = useState('');
  const location = useLocation();

  const debouncedChangeSearch = debounce(
    () => changeSearchJournal(inputSearch),
    600
  );

  const handleSearch = e => {
    setInputSearch(e.target.value);
    debouncedChangeSearch();
  };
  const resetSearch = () => {
    setInputSearch('');
    changeSearchJournal('');
  };

  useEffect(() => {
    const filter = new URLSearchParams(location.search).get("filter");
    if (filter) {
      queueMicrotask(() => {
        changeFilterJournal(filter.charAt(0).toUpperCase() + filter.slice(1));
      });
    }
  }, [location.search]);

  return (
    <Center>
      <Container>
        <Left>
          <StyledButton
            key={'all'}
            onClick={e => {
              e.preventDefault();
              changeFilterJournal(null);
            }}
            active={
              filterJournal === null
                ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
                : 'border-b-2 border-solid border-transparent'
            }
          >
            All
          </StyledButton>
          {["News","Stories","Events","Board","Volunteer", "Ideas + Insights"].map(type => (
            <StyledButton
              key={type}
              onClick={e => {
                e.preventDefault();
                changeFilterJournal(type);
              }}
              active={
                filterJournal === type
                  ? 'text-brand-blue border-b-2 border-solid border-brand-blue'
                  : 'border-b-2 border-solid border-transparent'
              }
            >
              {type}
            </StyledButton>
          ))}
        </Left>
        <Right>
          <Search>
            <FontAwesomeIcon className="absolute left-0 ml-2" icon={faSearch} />
            <SearchInput
              placeholder="Search"
              value={inputSearch}
              onChange={handleSearch}
            />
            {searchJournal && (
              <button
                className="absolute right-0 mr-2 hover:text-brand-blue"
                onClick={resetSearch}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </Search>
        </Right>
      </Container>
    </Center>
  );
};

JournalFilter.propTypes = {
  items: PropTypes.array.isRequired,
  filterJournal: PropTypes.string,
  changeFilterJournal: PropTypes.func.isRequired,
  searchJournal: PropTypes.string,
  changeSearchJournal: PropTypes.func.isRequired,
};

export default JournalFilter;
